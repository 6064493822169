import { useEffect, useState } from "react";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { Product } from "@ludens-reklame/rubics-v3-sdk/product/types";
import { useCart } from "@ludens-reklame/rubics-v3-react/cart/hooks";
import { createOrder } from "@ludens-reklame/rubics-v3-sdk/order";
import { ProfileContactInfo } from "@ludens-reklame/rubics-v3-sdk/profile/types";
import { useProfile } from "@ludens-reklame/rubics-v3-react/profile/hooks";
import { File } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { deleteFile, uploadFile } from "@ludens-reklame/rubics-v3-sdk/file";
import { arrow, trash } from "../../icons/svg.js";
import { Busy } from "../../internal-components/busy/busy.js";
import { RichText } from "../../internal-components/richText/RichText.js";
import { Stepper } from "./stepper.js";
import {
  Consents,
  SettableConsent,
} from "../../internal-components/consents/consents.js";
import { handleConsents } from "../../client-utils/handleConsents.js";
import { renderTaxString } from "../../client-utils/renderTaxString.js";

type ContactInfo = Partial<ProfileContactInfo> & { paymentReference?: string };

interface ProductGroup {
  name?: string;
  skus?: string;
}

interface Props {
  productText?: string;
  product?: Product;
  productGroups?: ProductGroup[];
  consentText?: string;
  consents?: SettableConsent[];
}

const MemberSignup: React.FC<Props> = ({
  productText,
  consentText,
  product,
  productGroups = [],
  consents = [],
}) => {
  const context = useStore().context;
  const { profile } = useProfile();
  const [creatingOrder, setCreatingOrder] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [emails, setEmails] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [billing, setBilling] = useState<ContactInfo>(profile?.billing || {});
  const _product = product || context.product;

  function renderStep() {
    if (!_product) {
      return null;
    }

    switch (step) {
      case 5:
        return (
          <div>
            <div className="section">
              <h1 className="h3">Du er snart medlem av Lyskultur!</h1>
            </div>
            <div className="section">
              <Stepper steps={5} activeStep={step} />
            </div>
            {!profile?.anonymous ? (
              <>
                <div className="section">
                  <h2 className="h5">
                    Det kan ta noen minutter å aktivere medlemskapet ditt.
                  </h2>
                </div>
              </>
            ) : (
              <>
                <div className="section">
                  <h2 className="h5">
                    Siste steg er å registrere en ny bruker.
                  </h2>
                </div>
                <div className="button-list">
                  <a
                    className="button secondary"
                    href={`/logg-inn?redirect=/min-side&email=${encodeURIComponent(
                      profile?.email || billing.email || ""
                    )}`}
                  >
                    Registrer ny bruker {arrow}
                  </a>
                </div>
              </>
            )}
          </div>
        );
      case 4:
        return (
          <Busy busy={creatingOrder}>
            <form
              onSubmit={async (e) => {
                e.preventDefault();

                if (creatingOrder) {
                  return;
                }

                try {
                  setCreatingOrder(true);

                  const consents: ProfileContactInfo = Object.fromEntries(
                    new FormData(e.currentTarget)
                  );

                  await handleConsents(consents, context.url.pathname);
                  await createOrder({
                    billing,
                    meta: [
                      { key: "emails", value: emails },
                      { key: "certificate", value: file || "" },
                      {
                        key: "paymentReference",
                        value: billing.paymentReference || "",
                      },
                    ],
                  });

                  setStep(5);
                } catch (error) {
                  console.error(error);
                  window.alert(
                    "Kunne ikke opprette ordren, vennligst prøv igjen eller kontakt oss for hjelp"
                  );
                } finally {
                  setCreatingOrder(false);
                }
              }}
            >
              <div className="section">
                <h1 className="h3">Samtykke</h1>
              </div>
              <div className="section">
                {consentText && <RichText html={consentText} />}
              </div>
              <div className="section">
                <Consents consents={consents} />
              </div>
              <div className="buttons">
                <button
                  type="button"
                  className="quaternary"
                  onClick={() => setStep(3)}
                >
                  Tilbake
                </button>
                <button
                  disabled={creatingOrder}
                  type="submit"
                  className="secondary"
                >
                  {creatingOrder ? "Laster…" : <>Send inn {arrow}</>}
                </button>
              </div>
            </form>
          </Busy>
        );
      case 3:
        return (
          <Shipping
            setData={(emails, file) => {
              setEmails(emails);
              setFile(file);
            }}
            setStep={setStep}
          />
        );
      case 2:
        return (
          <Billing
            data={{ ...billing, email: billing.email || profile?.email }}
            setData={(data) => {
              setBilling(data);
            }}
            setStep={setStep}
          />
        );
      case 1:
        return <Core setStep={setStep} productText={productText} />;
      default:
        return (
          <GroupSelect
            productText={productText}
            product={_product}
            productGroups={productGroups}
            setStep={setStep}
          />
        );
    }
  }

  if (!_product) {
    return null;
  }

  return (
    <div className="mw-medium hs vs-xl">
      <div className="member-signup card">
        <div className="mw-small">
          {step !== 5 && (
            <div className="section">
              <Stepper steps={5} activeStep={step} />
            </div>
          )}
          {renderStep()}
        </div>
      </div>
    </div>
  );
};

interface GroupSelectProps
  extends Required<Pick<Props, "product" | "productGroups">> {
  productText?: string;
  setStep: (step: number) => void;
}

const GroupSelect: React.FC<GroupSelectProps> = ({
  productText,
  product,
  productGroups,
  setStep,
}) => {
  const cart = useCart();
  const context = useStore().context;
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [selectedVariant, setSelectedVariant] = useState<string>("");

  useEffect(() => {
    if (!selectedVariant) {
      const item = cart.cart.items[0];

      if (item && item.product.sku) {
        const group = productGroups.find((g) => {
          const skus = g.skus?.split(",") || [];

          return skus.includes(item.product.sku!);
        });

        setSelectedVariant(item.product._id);

        if (group) {
          setSelectedGroup(group.name || "");
        }
      }
    }
  }, [selectedVariant, cart.cart.items]);

  return (
    <Busy busy={cart.networking}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await cart.update({
            items: selectedVariant
              ? [{ product: selectedVariant, quantity: 100 }]
              : [],
          });
          setStep(1);
        }}
      >
        <fieldset>
          <div className="section">
            <legend>
              <h1 className="h3">Velg gruppen som passer best</h1>
            </legend>
          </div>
          <div className="section">
            {productGroups.map((group) => {
              const skus = group.skus?.split(",") || [];
              const products = product.variants.filter(
                (v) => v.sku && skus.includes(v.sku)
              );
              const checked =
                group.name && selectedGroup === group.name ? true : false;

              return (
                <div key={group.name} className="radios">
                  <label className="h6">
                    <input
                      type="radio"
                      name="group"
                      required
                      checked={checked}
                      onChange={() => setSelectedGroup(group.name || "")}
                    />
                    {group.name}
                  </label>
                  {checked &&
                    products.map((p) => (
                      <label key={p._id}>
                        <input
                          type="radio"
                          name={group.name}
                          required
                          checked={selectedVariant === p._id}
                          onChange={() => setSelectedVariant(p._id)}
                        />
                        {p.name} - {p.priceText}
                        {renderTaxString(product.tax, context.pricesIncludeTax)}
                      </label>
                    ))}
                </div>
              );
            })}
          </div>
        </fieldset>
        <div className="section">
          {productText && <RichText className="b3" html={productText} />}
        </div>
        <div className="buttons">
          <button type="submit" className="secondary">
            Neste {arrow}
          </button>
        </div>
      </form>
    </Busy>
  );
};

interface CoreProps {
  productText?: string;
  setStep: (step: number) => void;
}

const Core: React.FC<CoreProps> = ({ setStep, productText }) => {
  const { profile, networking, update } = useProfile();

  return (
    <Busy busy={networking}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          if (networking) {
            return;
          }

          const formData = new FormData(e.currentTarget);
          const data = Object.fromEntries(formData.entries());

          try {
            await update(data);
            setStep(2);
          } catch (error) {
            console.error(error);
            window.alert(
              "Kunne ikke oppdatere informasjonen, vennligst prøv igjen eller kontakt oss for hjelp"
            );
          }
        }}
      >
        <div className="section">
          <h1 className="h3">Dine opplysninger</h1>
        </div>
        <div className="section">
          <label className="b3">
            Organisasjon / firma:*
            <input
              className="b1"
              type="text"
              name="company"
              autoComplete="organization"
              placeholder="Bedrift AS"
              defaultValue={profile?.company}
              required
            />
          </label>
          <label className="b3">
            Organisasjonsnummer:*
            <input
              className="b1"
              type="text"
              name="vat"
              placeholder="123456789"
              defaultValue={profile?.vat}
              minLength={9}
              maxLength={9}
              required
            />
          </label>
          <label className="b3">
            Ditt fornavn:*
            <input
              className="b1"
              type="text"
              name="firstName"
              autoComplete="given-name"
              placeholder="Kari"
              defaultValue={profile?.firstName}
              required
            />
          </label>
          <label className="b3">
            Ditt etternavn:*
            <input
              className="b1"
              type="text"
              name="lastName"
              autoComplete="family-name"
              placeholder="Nordmann"
              defaultValue={profile?.lastName}
              required
            />
          </label>
          <label className="b3">
            Din e-post:*
            <input
              className="b1"
              type="email"
              name="email"
              autoComplete="email"
              placeholder="epost@eksempel.no"
              defaultValue={profile?.email}
              required
            />
          </label>
        </div>
        <div className="section">
          {productText && <RichText className="b3" html={productText} />}
        </div>
        <div className="buttons">
          <button
            type="button"
            className="quaternary"
            onClick={() => setStep(0)}
          >
            Tilbake
          </button>
          <button type="submit" className="secondary">
            {networking ? "Laster…" : <>Neste {arrow}</>}
          </button>
        </div>
      </form>
    </Busy>
  );
};

interface BillingProps {
  data: ContactInfo;
  setData: (data: ContactInfo) => void;
  setStep: (step: number) => void;
}

const Billing: React.FC<BillingProps> = ({ data, setData, setStep }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const data = Object.fromEntries(formData.entries());
        setData(data);
        setStep(3);
      }}
    >
      <div className="section">
        <h1 className="h3">Fakturainfo</h1>
      </div>
      <div className="section">
        <label className="b3">
          Fakturaadresse:*
          <input
            className="b1"
            type="text"
            name="addressLine1"
            autoComplete="billing work address-line1"
            placeholder="Norgeveien 1"
            defaultValue={data?.addressLine1}
            required
          />
        </label>
        <label className="b3">
          Postnummer:*
          <input
            className="b1"
            type="text"
            name="postcode"
            autoComplete="billing work postal-code"
            defaultValue={data?.postcode}
            required
          />
        </label>
        <label className="b3">
          Sted:*
          <input
            className="b1"
            type="text"
            name="city"
            autoComplete="billing work address-level2"
            defaultValue={data?.city}
            required
          />
        </label>
        <label className="b3">
          Faktura-e-post:*
          <input
            className="b1"
            type="email"
            name="email"
            autoComplete="billing work email"
            placeholder="epost@eksempel.no"
            defaultValue={data?.email}
            required
          />
        </label>
        <label className="b3">
          Innkjøpsnr./ faktura merkes med:
          <input
            className="b1"
            type="string"
            name="paymentReference"
            defaultValue={data?.paymentReference}
          />
        </label>
      </div>
      <div className="buttons">
        <button type="button" className="quaternary" onClick={() => setStep(1)}>
          Tilbake
        </button>
        <button type="submit" className="secondary">
          Neste {arrow}
        </button>
      </div>
    </form>
  );
};

interface ShippingProps {
  setData: (emails: string[], file: File | null) => void;
  setStep: (step: number) => void;
}

const Shipping: React.FC<ShippingProps> = ({ setData, setStep }) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const data = Object.fromEntries(formData.entries());
        const emails =
          typeof data.emails === "string" && data.emails.length > 0
            ? data.emails.split("\n")
            : [];

        setData(emails, file);
        setStep(4);
      }}
    >
      <div className="section">
        <h1 className="h3">Informasjon</h1>
      </div>
      <div className="section">
        <p>
          Dersom flere personer ønsker Lyskulturs elektroniske
          medlems-informasjon, sett inn e-postadresser her:
        </p>
        <label className="b3">
          E-postadresser (en på hver linje):
          <textarea
            className="b1"
            name="emails"
            placeholder="kari@epost.no&#10;ola@e-post.no&#10;fido@e-post.no"
          />
        </label>
      </div>
      <div className="section">
        <Busy busy={uploading}>
          <label className="b3">
            Last opp firmaattest (PDF):
            {file ? (
              <p>
                <button
                  type="button"
                  className="delete"
                  aria-label="Slett filen"
                  onClick={async () => {
                    if (
                      window.confirm("Er du sikker på at du vil slette filen?")
                    ) {
                      try {
                        setUploading(true);
                        await deleteFile(file._id);
                        setFile(null);
                      } catch (error) {
                        console.error(error);
                        window.alert(
                          "Kunne ikke slette filen, vennligst prøv igjen eller kontakt oss for hjelp"
                        );
                      } finally {
                        setUploading(false);
                      }
                    }
                  }}
                >
                  {trash}
                </button>
                Fil lastet opp: {file.name}
              </p>
            ) : (
              <input
                type="file"
                accept="application/pdf"
                onChange={async (e) => {
                  try {
                    const fileData = e.target.files?.[0];

                    if (fileData) {
                      if (fileData.size > 30 * 1024 * 1024) {
                        window.alert("Filen er for stor, maks 30 MB");
                        return;
                      }

                      setUploading(true);
                      const file = await uploadFile(fileData);

                      if (!file) {
                        throw new Error("File upload failed");
                      }

                      setFile(file);
                    }
                  } catch (error) {
                    console.error(error);
                    window.alert(
                      "Kunne ikke laste opp filen, vennligst prøv igjen eller kontakt oss for hjelp"
                    );
                  } finally {
                    setUploading(false);
                  }
                }}
              />
            )}
          </label>
        </Busy>
      </div>
      <div className="buttons">
        <button
          type="button"
          disabled={uploading}
          className="quaternary"
          onClick={() => setStep(2)}
        >
          Tilbake
        </button>
        <button type="submit" disabled={uploading} className="secondary">
          Neste {arrow}
        </button>
      </div>
    </form>
  );
};

export default MemberSignup;
